"use client";

import { PropsWithChildren } from "react";

import { useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

import LandingPageThemeProvider from "@/styles/LandingPageThemeProvider";

export function CommonLayout({ children }: PropsWithChildren) {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    return (
        <LandingPageThemeProvider>
            {children}
            {/* {isMobile ? (
                <Box
                    sx={{
                        width: "100vw",
                        height: "100%",
                        position: "relative",
                        zIndex: 9999,
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            // zIndex: 9999,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                    </Box>

                    {children}
                </Box>
            ) : (
                <Box
                    sx={{
                        width: "100vw",
                        height: "100%",
                        position: "relative",
                        zIndex: 9999,
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            paddingLeft: "120px",
                            paddingRight: "120px",
                            // zIndex: 9999,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />{" "}
                        <Box
                            sx={{
                                backgroundColor: "red",
                                opacity: "0.1",
                                flex: 1,
                            }}
                        />
                    </Box>

                    {children}
                </Box>
            )} */}
        </LandingPageThemeProvider>
    );
}
